import React from 'react';
import Loginbutton from "./login-button/loginbutton";

export const Navbar = () => {
    return <div className={'p-menubar flex justify-content-end'}>
        <Loginbutton />
    </div>

}

export default Navbar;