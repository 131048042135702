import React from "react";
import {InputNumber} from "primereact/inputnumber";

export const NumberFormItem = ({label, value, onChange, coeffValue, onCoeffChange, icon, fractional=true}) => {
    return <div className="flex-auto m-1" >
        <label className="font-bold block mb-2">{label}</label>
        <div className="p-inputgroup">
            {!!icon && <span className="p-inputgroup-addon"> <i className={`pi ${icon}`}></i> </span>}
            <InputNumber style={{flexGrow: 2}} value={value} onValueChange={(e) => onChange(e.value)} maxFractionDigits={fractional && 3} />

            {!!coeffValue && <React.Fragment>
                <span className="p-inputgroup-addon">
                    <i className={`pi pi-times`}/>
                </span>
                <span className="p-inputgroup-addon p-0">
                    <InputNumber
                        className={'max-w-8rem'}
                        value={coeffValue}
                        disabled={!onCoeffChange}
                        onValueChange={(e) => onCoeffChange(e.value.toFixed(3))}
                        maxFractionDigits={3} showButtons step={0.1 }/>
                </span>
            </React.Fragment> }
        </div>
    </div> 
}
