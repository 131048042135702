export const RowDetail = (props) => {
    console.log(props)
    return <div className="flex flex-wrap gap-3 ">
        { Object.keys(props).map(e => {
            return <div className="wrap-auto" key={e}>
                <label className="font-bold mr-1">{e}:</label><span>{props[e]}</span>
            </div>
        })}
        </div>
}

export default RowDetail;