import React, {useState} from "react";
import {NumberFormItem} from "./number-form-item";
import {Button} from "primereact/button";

export const Calculator = ({onCalc}) => {
    
    const [loon, setLoon] = useState(0)
    const [coefficient, setCoefficient] = useState(2.099)
    const [dimona, setDimona] = useState(0.49)
    const [woonwerk, setWoonwerk] = useState(0)
    const [woonwerkCoeff, setWoonwerkCoeff] = useState(1.5)
    const [retentie, setRetentie] = useState(3.75)
    const [btw, setBtw] = useState(21)
    const [nettoverg, setNettoverg ] = useState(0)
    const [nettovergCoeff, setNettovergCoeff ] = useState(1.5)
    const [nettovergdag, setNettovergdag ] = useState(5)
    const [ploegpr, setPloegpr ] = useState(0)
    const [prestd, setPrestd ] = useState(130)
    const [urendag, setUrendag ] = useState(9)
    const [modified, setModified] = useState(true)
    
    const calc = () => {
        let _retentie = 1 + retentie/100;
        let _btw = 1 + btw/100;
        let nettoverguur = (nettoverg * nettovergCoeff) / (nettovergdag*urendag)
        let woonwerkverguur = (woonwerk * woonwerkCoeff) / urendag;
        let kostPerUurExBtw= (((loon * coefficient) + (dimona) + ploegpr + nettoverguur + woonwerkverguur) * _retentie).toFixed(2) ;
        let kostPerDagExBtw = ((kostPerUurExBtw * urendag)).toFixed(2);
        let kostPerUurRetBtwIn = (kostPerUurExBtw * _btw).toFixed(2);
        let kostPerDagRetBtwIn = (kostPerDagExBtw * _btw).toFixed(2);
        let kostTermijnExBtw = (kostPerDagExBtw * prestd).toFixed(2)
        let kostTermijnBtwIn = (kostPerDagExBtw * prestd * _btw).toFixed(2)
        
        onCalc({
            loon,
            coefficient,
            dimona,
            woonwerk,
            woonwerkCoeff,
            nettoverg,
            nettovergCoeff,
            ploegpr,
            prestd,
            btw,
            retentie,
            kostPerUurExBtw,
            kostPerDagExBtw,
            kostPerUurRetBtwIn,
            kostPerDagRetBtwIn,
            kostTermijnExBtw,
            kostTermijnBtwIn
        })
        setModified(false)
    }
    
    const change = (fn) => (e) => {
        setModified(true)
        return fn(e);
    }
    
    return <div className="flex flex-column">
        <div className="card flex flex-wrap gap-3 p-fluid align-items-center ">
            <NumberFormItem
                icon='pi-euro'
                label={'Loon / uur'}
                value={loon}
                onChange={change(setLoon)}
                coeffValue={coefficient}
                onCoeffChange={change(setCoefficient)}
            />
            {/*<NumberFormItem icon='pi-times' value={coefficient} label={'Coëfficient'} onChange={change(setCoefficient)} />*/}
            <NumberFormItem icon='pi-euro' value={dimona} label={'Dimona'} onChange={change(setDimona)} />
            <NumberFormItem
                icon='pi-euro'
                value={woonwerk}
                label={'Woon-werkverkeer'}
                onChange={change(setWoonwerk)}
                coeffValue={woonwerkCoeff}
                onCoeffChange={change(setWoonwerkCoeff)}
            />
        </div>
        <div className="card flex flex-wrap gap-3 p-fluid">
            <NumberFormItem
                icon='pi-euro'
                value={nettoverg}
                label={'Nettovergoeding'}
                onChange={change(setNettoverg)}
                coeffValue={nettovergCoeff}
                onCoeffChange={change(setNettovergCoeff)}
            />
            <NumberFormItem icon='pi-calendar' value={nettovergdag} label={'Nettoverg. dag / week'} onChange={change(setNettovergdag)} fractional={false}/>
            <NumberFormItem icon='pi-euro' value={ploegpr} label={'Ploegenpremie'} onChange={change(setPloegpr)} />
        </div>
        <div className="card flex flex-wrap gap-3 p-fluid">
            <NumberFormItem icon='pi-calendar' value={prestd} label={'Prestatiedagen'} onChange={change(setPrestd)} fractional={false} />
            <NumberFormItem icon='pi-clock' value={urendag} label={'Uren / dag'} onChange={change(setUrendag)} fractional={false} />
            <NumberFormItem icon='pi-percentage' value={btw} label={'BTW'} onChange={change(setBtw)} />
            <NumberFormItem icon='pi-percentage' value={retentie} label={'Retentievergoeding'} onChange={change(setRetentie)} />
        </div>
        
        <div className={'flex justify-content-between align-items-end m-1'}>
            {modified
            ? 'Nieuwe berekening'
            : 'Berekend'}
            <Button disabled={!modified} severity='success' raised className={'mt-3'} onClick={calc}>Bereken</Button>
        </div>
    </div>
}

export default Calculator;
