import './App.css';
import React, { useContext } from 'react';
import { PrimeReactContext } from 'primereact/api';
import Navbar from "./components/nav-bar/navbar";
import { Route, Routes } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { ProgressSpinner } from 'primereact/progressspinner';
import AuthenticationGuard from './authentication-guard/authentication-guard';
import CalcPage from './pages/calc-page';


//use in a component
function App () {
	const { setRipple } = useContext(PrimeReactContext);
	setRipple(true);

	const { isLoading } = useAuth0();

	if (isLoading) {
		return (
			<div className="flex justify-content-center">
				<ProgressSpinner />
			</div>
		);
	}
	return (
		<div style={{ padding: '0.5em' }}>
			<Navbar/>
			<Routes>
				<Route path={"/"}
					   element={<AuthenticationGuard component={CalcPage}/>}
				/>
			</Routes>
		</div>
	);
}

export default App;
