import { withAuthenticationRequired } from "@auth0/auth0-react";
import React from "react";
import { ProgressSpinner } from 'primereact/progressspinner';

export const AuthenticationGuard = ({ component }) => {
	const Component = withAuthenticationRequired(component, {
		onRedirecting: () => (
			<div className="page-layout">
				<ProgressSpinner/>
			</div>
		),
	});

	return <Component />;
};
export default AuthenticationGuard;
