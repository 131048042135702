import React, { useState } from 'react';
import Calculator from '../components/calculator/calculator';
import { Divider } from 'primereact/divider';
import Table from '../components/history-table/table';


export const CalcPage = () => {
	const [history, setHistory] = useState([])

	const addToHistory = (o) => {
		setHistory([o, ...history])
	}

	return <React.Fragment>
		<Calculator onCalc={addToHistory}/>

		<Divider/>
		<Table items={history}/>
	</React.Fragment>
}

export default CalcPage;
