import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PrimeReactProvider } from "primereact/api";
import '/node_modules/primeflex/primeflex.css';
import 'primereact/resources/themes/vela-green/theme.css';
import 'primereact/resources/primereact.min.css'
import 'primeicons/primeicons.css';
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<PrimeReactProvider>
			<BrowserRouter>

				<Auth0Provider
					domain="dev-rvnaltherdvbkmhe.us.auth0.com"
					clientId="1yYRItLwIIU7dqNWZXKdzHXTp5qBbqjI"
					authorizationParams={{ redirect_uri: window.location.origin }}
				>
					<App/>
				</Auth0Provider>
			</BrowserRouter>
		</PrimeReactProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
