import React from "react";
import {Button} from 'primereact/button'
import {useAuth0} from "@auth0/auth0-react";

export const Loginbutton = () => {
    const {loginWithRedirect, isAuthenticated, logout} = useAuth0();
    console.log(isAuthenticated)
    return <Button outlined
                   severity={isAuthenticated ? 'danger' : 'success'}
                   onClick={isAuthenticated ? logout : loginWithRedirect}>
        {isAuthenticated ? 'Log out' : 'Log in'}
    </Button>
}

export default Loginbutton;
