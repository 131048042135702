import React, {useState} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import RowDetail from "./row-detail";

export const Table = ({items}) => {
    const [expandedrows, setExpandedrows] = useState([])

    console.log(items)

    return <DataTable className="m-1" value={items}
        expandedRows={expandedrows}
            rowExpansionTemplate={RowDetail} stripedRows
        onRowToggle={e => setExpandedrows(e.data)}
        >
        <Column expander={true} />
        <Column field={'loon'} header={'Loon'}/>
        <Column field={'coefficient'} header='Coëfficient'/>
        <Column field={'prestd'} header = 'Prestatiedagen'/>
        <Column field={'kostPerUurExBtw'} header='€ / uur ex. BTW'/> 
        <Column field={'kostPerDagExBtw'} header='€ / dag ex. BTW'/> 
        <Column field={'kostTermijnExBtw'} header='€ termijn ex. BTW'/> 
        <Column field={'kostPerUurRetBtwIn'} header='€ / uur BTW'/> 
        <Column field={'kostPerDagRetBtwIn'} header='€ / dag BTW'/> 
        <Column field={'kostTermijnBtwIn'} header='€ termijn BTW'/> 
    </DataTable>
}

export default Table;